define("@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wkfs0e19",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"role\",\"mount-point\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,0,[\"stripeElement\"]],[23,0,[\"stripeError\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-card-number.hbs"
    }
  });

  _exports.default = _default;
});